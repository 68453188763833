<script>
const orderColumns = [
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Order Number",
    dataIndex: "order_id",
    scopedSlots: { customRender: "order_id" },
  },
  {
    title: "Points",
    dataIndex: "points",
    scopedSlots: { customRender: "points" },
  },
];
const columns = [
  {
    title: "Order Number",
    dataIndex: "id",
    key: "order",
    scopedSlots: { customRender: "order" },
  },
  {
    title: "Created",
    dataIndex: "created",
    key: "created",
  },
  {
    title: "Status",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "Action",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
  },
];
const modalOrderColumns = [
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
    scopedSlots: { customRender: "image" },
  },

  {
    title: "Product Title",
    dataIndex: "name",
    key: "product",
  },
  {
    title: "Quantity Ordered",
    dataIndex: "quantity",
    key: "quantity",
    scopedSlots: { customRender: "quantity" },
  },
  {
    title: "Total Points",
    dataIndex: "points",
    key: "points",
  },
];

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};

import Loader from "vue-element-loading";
import api from "@/api";
import ATabs from "ant-design-vue/lib/tabs";
import ATabPane from "ant-design-vue/lib/vc-tabs/src/TabPane";
import ATable from "ant-design-vue/lib/table";
import AEmpty from "ant-design-vue/lib/empty";
import AModal from "ant-design-vue/lib/modal";
import ATooltip from "ant-design-vue/lib/tooltip";
import AAvatar from "ant-design-vue/lib/avatar";
import AButton from "ant-design-vue/lib/button";
// import Popconfirm from "ant-design-vue/lib/popconfirm";
export default {
  name: "History",

  components: {
    Loader,
    AButton,
    ATabs,
    ATabPane,
    ATable,
    AEmpty,
    AModal,
    ATooltip,
    AAvatar,
  },
  data() {
    return {
      orderColumns,
      columns,
      rowSelection,
      modalOrderColumns,
      disclaimerText: "Wel well <a>well</a>",
      comingSoonTextPurchase:
        "Option to purchase points will soon be available.",
      orderItemModal: false,
      redeemItemModal: false,
      pointsEarningsLoading: false,
      ordersLoading: false,
      orders: [],
      redeems: [],
      orderPrefix: "#OD",
      singleOrder: [],
      totalPoints: null,
      history: [],
      loading: false,
      modalLoading: false,
    };
  },
  methods: {
    callback(key) {
      console.log(key);
    },
    openOrder(id) {
      this.redeemItemModal = true;
      this.modalLoading = true;
      api
        .singleRedeem(id)
        .then((response) => {
          const data = response.data.items;
          data.forEach((element) => {
            this.totalPoints = element.order.total_points;
            this.singleOrder.push({
              id: element.id,
              name: element.product.title,
              points: element.total_points,
              quantity: element.quantity,
              image: element.product.main_image,
              orderPoints: element.order.total_points,
            });
          });
          this.modalLoading = false;
        })
        .catch((error) => {
          console.log(error);
          if (error.responnse.status === 401) {
            this.$message.error("Session expired Login again!");
          }
          this.$message.error("Oops ran into an error: Try again!");
          this.modalLoading = false;
        });
    },
    // cancelOrder() {
    //   Popconfirm.confirm({
    //     title: "Are You sure you want to cancel this order?",
    //     content: "Terms and conditions apply",
    //     okText: "Yes",
    //     okType: "danger",
    //     cancelText: "No",
    //     onOk() {
    //       console.log("done");
    //       this.$message.info("Cancelled");
    //     },
    //   });
    // },
    handleOk(e) {
      console.log(e);
      this.singleOrder = [];
      (this.totalPoints = null), (this.redeemItemModal = false);
    },
    closeModal() {
      this.singleOrder = [];
      this.totalPoints = null;
      this.redeemItemModal = false;
    },
    // purchasePoints(points) {
    //   api.purchasePoints({ points: points }).then(() => {
    //     this.$message.success(`${points} purchased successfully`);
    //     this.$store.dispatch("Auth/UserStats");
    //     this.$router.push({ name: "History" });
    //   });
    // },
  },
  created() {
    this.$store.commit("Nav/SET_PAGE", "history");
    this.loading = true;
    this.ordersLoading = true;
    api
      .history()
      .then(({ data }) => {
        this.loading = false;
        this.history = data.history;
        this.pointsEarningsLoading = false;
      })
      .catch((e) => {
        this.loading = false;
        if (e.response.status === 401) {
          this.pointsEarningsLoading = false;
          // this.$message.error("Login Required");
        } else if (e.response.status === 400) {
          this.$message.error(e.response.data);
          this.pointsEarningsLoading = false;
        }
      });

    api
      .redeems()
      .then((response) => {
        this.redeems = response.data.redeems;
      })
      .catch(() => {
        // this.$message.error("Oops Error");
        this.modalLoading = false;
      });

    api
      .orders()
      .then(({ data }) => {
        this.orders = data.orders;
        this.ordersLoading = false;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.$message.info("Session Expired: Login Required");
          this.ordersLoading = false;
        } else if (error.response.status == 400) {
          this.$message.error(error.response.data);
          this.ordersLoading = false;
        }
      });
  },
};
</script>

<template>
  <div class="history container">
    <a-tabs type="card" @change="callback" defaultActiveKey="2">
      <a-tab-pane key="1" tab="Points Earnings">
        <a-table
          :loading="pointsEarningsLoading"
          :columns="orderColumns"
          :data-source="orders"
          rowKey="id"
        >
          <template slot></template>
          <template slot="order_id" slot-scope="text, record">
            {{ text }}
            <template v-if="record.site">({{ record.site }})</template>
          </template>
          <template slot="points" slot-scope="text"
            ><span class="points-earned">+{{ text }}</span></template
          >
          <template slot="footer">
            <p class="disclaimer">
              <strong>Disclaimer:</strong> Points are issued for orders that
              have been shipped, and placed after January 1, 2020. Any orders
              that have been cancelled or refunded, will have any points
              associated with that order deducted from your points total. Please
              see more details at our
              <router-link :to="{ name: 'TermsOfUse' }" class="disclaimer--link"
                >Terms Of Use</router-link
              >
            </p>
          </template>
        </a-table>
      </a-tab-pane>

      <a-tab-pane key="2" tab="Orders">
        <a-table
          :loading="ordersLoading"
          :columns="columns"
          :data-source="redeems"
          rowKey="id"
        >
          <template slot="order" slot-scope="text">
            <a-button type="link" class="link">{{
              orderPrefix + text
            }}</a-button>
          </template>
          <template slot="status" slot-scope="text, record">
            <template v-if="record.status.short_form == 'N'">
              <a-tooltip
                placement="topRight"
                title="Our system has picked your order! Goods Coming soon!"
              >
                <a class="pill-success">
                  <i class="fas fa-clipboard-check icon"></i>New Order
                </a>
              </a-tooltip>
            </template>
            <template v-else-if="record.status.short_form == 'P'">
              <a class="pill-success">
                <i class="fas fa-truck-loading icon"></i>Processing
              </a>
            </template>
            <template v-else-if="record.status.short_form == 'C'">
              <a class="pill-info">
                <i class="fas fa-truck icon"></i>Delivery in:
                <p class="small">(4-8 weeks)</p>
              </a>
            </template>
            <template v-else-if="record.status == 4">
              <p class="cancel-text">Order has been cancelled</p>
            </template>
          </template>
          <template slot="action" slot-scope="text, record">
            <a class="white-button-sm mr-2" @click="openOrder(record.id)">
              <i class="icon fas fa-dolly"></i>View Order
            </a>
            <!-- <a class="cancel-button" @click="cancelOrder(record.id)">X</a> -->
            <a-modal
              v-model="redeemItemModal"
              title="Your Order"
              @ok="handleOk"
              @cancel="closeModal"
            >
              <h1>Total Points: {{ totalPoints }}</h1>
              <loader :active="modalLoading" spinner="bar-fade-scale" />
              <a-table
                :columns="modalOrderColumns"
                :data-source="singleOrder"
                :bredeemed="true"
                rowKey="id"
              >
                <template slot="image" slot-scope="text, record">
                  <template v-if="record.image != null">
                    <a-avatar shape="square" :size="50" :src="record.image" />
                  </template>
                  <template v-else>
                    <a-avatar shape="square" :size="50">
                      <i class="fas fa-file-image"></i>
                    </a-avatar>
                  </template>
                </template>
                <template slot="quantity" slot-scope="text">
                  <p>{{ text }}</p>
                </template>
              </a-table>
            </a-modal>
          </template>
        </a-table>
      </a-tab-pane>

      <a-tab-pane key="4" tab="Purchase Points">
        <div class="points-purchase-area mt-10">
          <a-empty :description="comingSoonTextPurchase" />
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/styles/_components.scss";
@import "@/assets/styles/_mixins";
.small {
  margin-left: 0.6rem;
  font-weight: 400;
  font-size: 1.3rem;
  text-decoration: italic;
}
.link {
  cursor: default;
}
.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}
.mr-3 {
  margin-right: 3rem;
}
.icon {
  margin-right: 0.8rem;
}
.cancel-btn {
  margin-left: 3em;
}
.cancel-text {
  font-family: var(--open-sans);
  font-style: italic;
}
.history {
  position: relative;
  top: 7.8rem;
  // margin-top: 7.8rem;
}
.tracking-pane {
  margin-bottom: 4.7rem;
  .order-number {
    // color: var(--color-lapis-blue);
    text-decoration: none;
  }
}
.points-purchase-area {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  .points-area {
    margin-bottom: 1.1rem;
    height: 18.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .pnt-icon-buy {
      color: var(--color-deep-yellow);
      height: 2.3rem;
      width: 2.3rem;
      margin-right: 0.7rem;
    }
  }
}
.btn-area {
  text-align: center;
}
.points-earned {
  font-family: var(--montserrat);
  font-size: 1em;
  font-weight: 500;
  color: var(--bright-green);
}
.disclaimer {
  max-width: 87ch;
  font-size: 0.8em;
  color: var(--color-grey-dark-2);
}
</style>
